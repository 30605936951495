import {
  DocumentStatus,
  DownloadPdfParams,
  IRecipient,
  InvoiceType,
  LinkReference,
  RecipientRolesEnum,
  UpdateSignatureParams,
  counter,
  setFillableFieldsDataIntoDocument,
} from '@gohighlevel/ghl-proposals-common'
import { AxiosError, isAxiosError } from 'axios'
import { computed, inject, ref } from 'vue'

import { router } from '@/router'
import { DocumentServices } from '@/service/DocumentService'
import { useDocumentStore } from '@/store/document'
import { useNotification } from '@gohighlevel/ghl-ui'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { useCertificate } from './useCertificate'

const redirectingToInvoice = ref(false)
const validatingInvoice = ref(false)
const invoiceRedirectionDetails = ref({
  invoiceId: '',
  documentId: '',
})
const secondsTillRedirectToPayment = ref(10)
const loading = ref(false)
const pageData = ref<any>({})

export const useDocument = () => {
  const route = useRoute()
  const isPreview = inject<boolean>('isPreview')

  const store = useDocumentStore()
  const { t, locale } = useI18n()
  const {
    setDocumentData,
    setDocumentStatus,
    setIsEditing,
    setRecipientLinkReference,
    setPaymentInfo,
  } = store

  const isCurrantUserIsLastAcceptDoc = computed(() => {
    const n =
      store.recipients.length -
      store.recipients.filter(element => element.hasCompleted === true).length
    return n === 1 ? true : false
  })

  const isAllSigned = computed(() => {
    const signers = store.recipients.filter(
      ({ role }) => role === RecipientRolesEnum.SIGNER
    )
    if (signers && signers.length > 0) {
      return signers.every(({ hasCompleted }) => hasCompleted) || false
    }
    return false
  })

  const isEditable = computed(() => store.isEditable)
  const __document = computed(() => store.document)
  // const signatureElementCount = computed(() => store.signatureElementCount)
  const recipient = computed(() => {
    return store.activeRecipient || ({} as IRecipient)
  })
  const recipientLinkReference = computed(() => store.recipientLinkReference)
  const notification = useNotification()
  const { getCertificateDetails } = useCertificate()

  const enableNotification = (
    type: 'success' | 'info' | 'warning' | 'error',
    title: string,
    description: string
  ) => {
    notification[type]({
      title,
      description,
      duration: 10000,
    })
  }

  const getDocumentData = async (isPreview: boolean, isPDFView: boolean) => {
    try {
      loading.value = true
      let requestParams = {}
      if (route.name === 'document-v1' || route.name === 'status-success') {
        requestParams = {
          referenceId: (route?.params?.referenceId || '') as string,
        }
      } else {
        const recipientId: string = (route?.params?.recipientId || '') as string
        requestParams = {
          id: route.params.documentId as string,
          isPreview,
          recipientId,
        }
      }
      const { data } = await DocumentServices.getDocument(requestParams)
      setDocument(data)

      if (isAllSigned.value && isPDFView) {
        await getCertificateDetails()
      }
    } catch (error) {
      console.error(error)
      const message = error?.response?.data?.message || ''
      router.push({
        name: 'document-error',
        params: { type: '404' },
        query: { message },
      })
    } finally {
      loading.value = false
    }
  }

  const setDocument = (data: any) => {
    if (data) {
      const mappedPages = setFillableFieldsDataIntoDocument(
        data.document.pages,
        data.document.fillableFields || [],
        data.document.pricingTables
      )
      pageData.value = mappedPages
      setDocumentData({ ...data.document, pages: mappedPages })
      if (data?.recipientLinkReference) {
        setRecipientLinkReference(data.recipientLinkReference as LinkReference)
      }
      store.setInvoiceTypeAndIntervalType(
        data.document?.invoiceType,
        data.document?.intervalType
      )

      if (data?.paymentInfo) {
        setPaymentInfo(data.paymentInfo)
      }

      setIsEditing(
        isEditable.value && store.signatureElementCountForRecipient <= 0
      )
    }
  }

  const acceptSignature = async () => {
    try {
      loading.value = true
      const signedDateCCUser =
        recipient.value?.role === RecipientRolesEnum.USER
          ? new Date().toISOString()
          : ''
      const isFillableFieldsValid = store.validateFillableFields()
      if (!isFillableFieldsValid) {
        enableNotification(
          'error',
          'Error',
          t('documentViewer.fillableFieldsValidationMessage')
        )
        return
      }
      const fillableFields = store.getFillableFields()
      const requestParams = {
        ...(route.params.documentId && {
          documentId: route.params.documentId as string,
        }),
        ...(recipient.value?.id && { recipientId: recipient.value.id }),
        ...(recipient.value?.imgUrl && { signature: recipient.value?.imgUrl }),
        ...(recipient.value?.initialsImgUrl && {
          initials: recipient.value?.initialsImgUrl,
        }),
        signedDate:
          recipient.value?.signedDate || signedDateCCUser || new Date(),
        ...(recipientLinkReference.value?.referenceId && {
          referenceId: recipientLinkReference.value.referenceId,
        }),
        fillableFields,
        pricingTables: store.getPricingLineItems(),
      }
      const { data } = await DocumentServices.acceptDocument(
        requestParams as UpdateSignatureParams
      )
      if (data.success) {
        setDocumentStatus(DocumentStatus.ACCEPTED)
        const message = isCurrantUserIsLastAcceptDoc.value
          ? t('documentViewer.documentSignedMessage')
          : t('documentViewer.documentAccepterMessage')

        enableNotification('success', 'Success', message)
        setDocument(data?.document)
        if (data.invoiceType === InvoiceType.ONETIME) {
          await redirectToInvoice(data, route.params.referenceId as string)
        } else {
          await handleRecurringPayment(data)
        }
      }
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  async function pollRecurringInvoiceForPayment(mainData: any) {
    const referenceId = route?.params?.referenceId as string
    const { data } = await DocumentServices.fetchRecurringInvoiceForPayment(
      referenceId
    )
    if (data.invoiceId) {
      validatingInvoice.value = false
      await redirectToInvoice(
        {
          ...mainData,
          ...data,
        },
        referenceId
      )
    } else {
      setTimeout(() => {
        pollRecurringInvoiceForPayment(mainData)
      }, 1000 * 10)
    }
  }
  async function handleRecurringPayment(data: any) {
    if (data.enableDirectPayment && data.isDueInNextXMinutes) {
      validatingInvoice.value = true
      pollRecurringInvoiceForPayment(data)
    }
  }

  async function redirectToInvoice(data: any, refId: string) {
    if (data.invoiceId && data.enableDirectPayment) {
      redirectingToInvoice.value = true
      invoiceRedirectionDetails.value = {
        invoiceId: data.invoiceId,
        documentId: data.documentId,
      }
      counter({
        startCount: 5,
        timeGapInMs: 1000,
        onEnd: () => {
          const currentHost =
            window.location.origin +
            '/documents/v1/status/' +
            refId +
            '?invoice=' +
            data.invoiceId
          const url =
            data.url +
            '?redirectType=_self&redirectUrl=' +
            currentHost +
            `&locale=${route.query.locale || locale.value}`
          window.location.replace(url)
        },
        onTick: count => {
          secondsTillRedirectToPayment.value = count
        },
      })
    }
  }

  async function downloadPdf(isPreview = false, options?: DownloadPdfParams) {
    try {
      loading.value = true
      const { data } = await DocumentServices.downloadPdf(
        options
          ? options
          : {
              documentId: __document.value._id,
              altType: 'location',
              altId: __document.value.locationId,
              isPublicRequest: isPreview,
            }
      )
      return data
    } catch (err) {
      console.error(err)
      if (isAxiosError(err)) {
        const axiosError = err as AxiosError
        if (axiosError.response?.data) {
          enableNotification(
            'error',
            'Error',
            axiosError.response.data?.message as string
          )
        }
      }
    } finally {
      loading.value = false
    }
  }

  const downloadProposalPdf = async (options?: DownloadPdfParams) => {
    const data = await downloadPdf(!isPreview, options)
    if (data?.url) {
      const { url } = data
      const anchor = document.createElement('a')
      anchor.setAttribute('href', url)
      anchor.setAttribute('download', `${store.document.name}.pdf`)
      const clickHandler = () => {
        setTimeout(() => {
          URL.revokeObjectURL(url)
          removeEventListener('click', clickHandler)
        }, 150)
      }
      anchor.addEventListener('click', clickHandler, false)
      anchor.click()
    }
  }

  return {
    loading,
    pageData,
    getDocumentData,
    // saveSignature,
    isPreview,
    acceptSignature,
    downloadPdf,
    redirectingToInvoice,
    invoiceRedirectionDetails,
    secondsTillRedirectToPayment,
    downloadProposalPdf,
    validatingInvoice,
  }
}
