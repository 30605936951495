<script setup lang="ts">
import { UIInput, UITextSmRegular } from '@gohighlevel/ghl-ui'
import { useDocumentStore } from '@/store/document'
import { PropType, computed, inject, onMounted, ref, toRef, watch } from 'vue'
import {
  IElement,
  IRecipient,
  PageElementOptions,
  TextFieldElementOptions,
  Tooltip,
  getSignatureElementColorStyles,
} from '@gohighlevel/ghl-proposals-common'
import { useFillableFields, usePrintAdjust, useTypography } from '@/composable'

const props = defineProps({
  page: {
    type: Object as PropType<IElement<PageElementOptions>>,
    required: true,
  },
  element: {
    type: Object as PropType<IElement<TextFieldElementOptions>>,
    required: true,
  },
  pageId: {
    type: String,
    required: true,
  },
  tabIndex: {
    type: Number,
    required: true,
  },
  pageRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  parentRef: {
    required: false,
    type: Object as () => HTMLElement,
  },
  shift: {
    type: String,
    required: false,
    default: '0px',
  },
})
const localParentRef = ref<HTMLElement | undefined>(props.parentRef)
const localPageRef = ref<HTMLElement | undefined>(props.pageRef)
watch(
  () => props.parentRef,
  newVal => {
    localParentRef.value = newVal
  },
  { immediate: true }
)
watch(
  () => props.pageRef,
  newVal => {
    localPageRef.value = newVal
  },
  { immediate: true }
)
const { position: printPosition, elRef } = usePrintAdjust(
  props.page,
  props.element,
  localPageRef,
  localParentRef
)
const isPDFView = inject<boolean>('isPDFView')
const isPreview = inject<boolean>('isPreview')
const { toCapitalize } = useTypography()
const store = useDocumentStore()
const isCCrecipient = computed(() => store.isCcRecipient)
const activeRecipient = computed(() => store.activeRecipient)
const isEditing = computed(() => store.isEditing)
const { setActiveField, activeElement } = useFillableFields()
const isActiveTextFieldElemForRecipient = computed(
  () =>
    activeRecipient.value?.id === props.element?.component.options.recipient &&
    activeRecipient.value?.entityName ===
      props.element?.component.options.entityName
)

const recipient = computed(() => {
  const recipients = store.document?.recipients || []
  return (
    recipients.find(
      ({ id, entityName }) =>
        id === props.element?.component.options.recipient &&
        entityName === props.element?.component.options.entityName
    ) || ({} as IRecipient)
  )
})

const isRecipientHasCompleted = computed(() => {
  return (
    activeRecipient.value &&
    activeRecipient.value?.hasCompleted &&
    isActiveTextFieldElemForRecipient.value
  )
})

const disabled = computed(() => {
  return (
    !store.hasPrevRecipientsCompleted ||
    (!isPreview &&
      !isActiveTextFieldElemForRecipient.value &&
      !isRecipientHasCompleted.value) ||
    isCCrecipient.value
  )
})

const recipientName = computed(() => {
  const { firstName, lastName } = recipient.value
  return toCapitalize(firstName ? `${firstName} ${lastName || ''}` : '')
})

const date = toRef(recipient.value.signedDate)

const ghostStyles = computed(() => {
  if (props.element?.component.options?.isGhost) {
    const styleMap = props.element?.responsiveStyles.large as any
    const position = styleMap.position
    const scale = styleMap.scale
    const dimensions = styleMap.dimensions
    const stylesArray = []
    if (position) {
      let styleStr = position?.preferBottom
        ? `bottom: ${position.bottom}px;`
        : `top: ${position.top}px; `
      styleStr += position?.preferRight
        ? `right: ${position.right}px;`
        : `left: ${position.left}px;`
      styleStr += 'z-index: 100;'

      stylesArray.push(styleStr)
    }
    if (scale) {
      stylesArray.push(
        `transform: scale(${scale.scaleX}, ${scale.scaleY}) translate3d(0,0,0);`
      )
    }
    if (dimensions) {
      stylesArray.push(
        `${!props.element?.component.options.text ? 'height' : 'min-height'}: ${
          dimensions.height
        }px; width: ${dimensions.width}px;`
      )
    }
    stylesArray.push('position: absolute; width: auto; display: flex;')
    return stylesArray.join(' ').trim()
  }
  return ''
})

onMounted(() => {
  if (store.isAlreadyAccepted) {
    store.updateFillableElement(
      props.element?.id,
      props.pageId,
      props.element?.component.options.text as string,
      true
    )
  }
})
const onUpdateTextField = (text: string) => {
  const timestamp = new Date()
  date.value = timestamp.toISOString()
  store.updateFillableFieldForRecipient(
    recipient.value.id,
    text,
    props.element?.id as string,
    date.value
  )
  store.updateFillableElement(props.element?.id, props.pageId, text, true)
}

const styles = computed(() => getSignatureElementColorStyles(recipient.value))
const dimensions = computed(() => {
  const dimensionsObj = props.element?.responsiveStyles.large.dimensions
  return (
    {
      height: dimensionsObj?.height + 'px',
      width: dimensionsObj?.width + 'px',
    } || { height: 'auto', width: 'auto' }
  )
})
const commonStyles = computed(() => {
  return {
    '--n-color': 'transparent !important',
    [!props.element?.component.options.text
      ? 'height'
      : 'minHeight']: `${dimensions.value.height}`,
    width: dimensions.value.width,
    border: `1px solid ${
      props.element?.component.options.text
        ? 'transparent'
        : styles.value.borderColor
    } !important`,
  }
})
const colorStyles = computed(() => {
  if (isPDFView) {
    return {
      ...commonStyles.value,
      '--n-color-disabled': 'transparent !important',
      backgroundColor: 'transparent !important',
    }
  }
  if (disabled.value) {
    return {
      ...commonStyles.value,
      // border: '0px solid transparent !important',
      'box-shadow': 'none !important',
      '--n-border-hover': '0px solid transparent !important',
      '--n-border-disabled': '0px solid transparent !important',
      border: `0px solid transparent !important`,
    }
  }

  return {
    ...styles.value,
    ...commonStyles.value,
    '--n-border': `1px solid ${styles.value.borderColor} !important`,
    border: `1px solid ${styles.value.borderColor} !important`,
  }
})
const backgroundColor = computed(
  () =>
    (colorStyles.value as ReturnType<typeof getSignatureElementColorStyles>)
      .backgroundColor || 'transparent'
)

const onClick = () => {
  if (
    !isEditing.value &&
    isActiveTextFieldElemForRecipient.value &&
    !activeRecipient.value?.hasCompleted &&
    isCCrecipient.value === false
  ) {
    store.setIsEditing(true)
  }
  setActiveField(props.element?.id as string)
}
</script>

<template>
  <div
    ref="elRef"
    class="text-field-el !break-inside-avoid"
    :class="{ filled: props.element?.component.options.text }"
    :style="ghostStyles"
    :id="props.element?.id"
  >
    <Tooltip direction="bottom" :enable="true">
      <template #trigger>
        <div
          class="!break-inside-avoid box-border text-field-el-wrapper-viewer"
          @click="onClick"
        >
          <span
            v-if="
              element?.component.options.required &&
              !disabled &&
              !isPDFView &&
              !isRecipientHasCompleted
            "
            class="option--required"
            >*</span
          >
          <span
            v-if="element?.component.options.textFieldErrorMessage"
            class="option--error"
            >{{ element?.component.options.textFieldErrorMessage }}</span
          >
          <UIInput
            :tabindex="tabIndex"
            class="textarea-field-viewer rounded-sm items-center text-sm font-medium"
            :class="{
              'self-completed':
                props.element?.component.options.text &&
                isRecipientHasCompleted,
              completed:
                props.element?.component.options.text &&
                !isActiveTextFieldElemForRecipient,
              '!border-0 no-active': isPDFView
                ? true
                : props.element?.component.options.text ||
                  isRecipientHasCompleted,
              'active-el': isPDFView ? true : !isRecipientHasCompleted,
              '!border-2 !border-error-500':
                element?.component.options.textFieldErrorMessage,
            }"
            :style="{
              ...(!props.element?.component.options.text
                ? colorStyles
                : commonStyles),
              'border-width':
                activeElement && activeElement.id === props.element?.id
                  ? '0px'
                  : '1px',
            }"
            :id="`floating_text_field-${props.element?.id}`"
            :placeholder="props.element?.component.options.placeholder"
            :readonly="(isRecipientHasCompleted as boolean)"
            type="textarea"
            :maxlength="undefined"
            :minlength="undefined"
            :loading="undefined"
            :rows="1"
            :autosize="{ minRows: 1, maxRows: 500 }"
            :clearable="false"
            size="medium"
            :showCount="false"
            :disabled="disabled"
            :modelValue="props.element?.component.options.text"
            @update:modelValue="val => onUpdateTextField(val)"
          />
        </div>
      </template>
      <template #content>
        <UITextSmRegular
          class="capitalize font-semibold px-2 py-1 text-xs"
          :style="{ color: styles.placeholderColor }"
          >{{ recipientName }}</UITextSmRegular
        >
      </template>
    </Tooltip>
  </div>
</template>

<style lang="scss">
// .text-field-el.point-el {
//   // box-shadow: 0px 0px 0px 2px red;
// }
.text-field-el-wrapper-viewer {
  position: relative;
  .textarea-field-viewer {
    --n-border: 1px solid transparent !important;
    --n-border-hover: 1px solid v-bind('styles.borderColor') !important;
    --n-border-focus: 1px solid v-bind('styles.borderColor') !important;
    --n-color-focus: v-bind('styles.backgroundColor') !important;
    box-shadow: none !important;
    border-radius: 2px;
    --n-color-disabled: #f1f1f1 !important;
    --n-text-color-disabled: #333 !important;
    --n-border-disabled: 1px solid transparent !important;
    display: flex !important;
    align-items: flex-start !important;
    textarea.n-input__textarea-el {
      font-weight: 600 !important;
    }
    &.n-input.n-input--disabled {
      .n-input__placeholder {
        color: #c8c8c8 !important;
      }
    }
    &.completed,
    &.self-completed {
      pointer-events: none !important;
      --n-color-disabled: transparent !important;
    }
  }
  .option--required {
    position: absolute;
    right: -7px;
    top: -17px;
    color: red;
    font-size: 22px;
    font-weight: 700;
  }
  .option--error {
    position: absolute;
    top: -8px;
    font-size: 10px;
    color: red;
    z-index: 30;
    background: v-bind('backgroundColor');
    left: 10px;
    padding: 0 5px;
    min-width: 112px;
  }
  .n-input-wrapper {
    min-height: unset !important;
    padding: 0 !important;
    .n-input__suffix {
      display: none !important;
    }
    .n-input__textarea {
      .n-input__textarea-el {
        padding: 0px 2px !important;
      }
      .n-input__placeholder {
        padding: 0px 2px !important;
        color: v-bind('styles.placeholderColor') !important;
      }
    }
    .n-input__textarea {
      .n-input__textarea-mirror,
      .n-input__textarea-el {
        padding: 0px 2px !important;
        height: 100%;
        overflow: hidden;
      }
    }
  }
}

@media print {
  .text-field-el {
    break-inside: avoid-page !important;
    // top: v-bind('topPosition') !important;
    transform: v-bind('printPosition.top') !important;
  }
}
</style>
